import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CorpsService } from "../../services/admin.service";
import { UploadService } from "../../services/upload.service";
import { toast } from "react-toastify";

interface IUploadCenters {
    show: boolean;
    handleClose: any;
}

export default function UploadCenters (props: IUploadCenters) {

    const [file, setFile] = useState<any>(undefined);

    const handleFileSelection = (e: any) => {
        setFile(e.target.files[0]);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("upload", file);
        UploadService.uploadCenters(formData).then(res => {
            if (res.status === 200) {
                toast.success("File uploaded successfully");
                props.handleClose();
            }
        }).catch(e => {
            toast.error(e.response.data || "Error uploading file");
        })
    }


    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Centers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Upload File</Form.Label>
                    <Form.Control type="file" onChange={handleFileSelection} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleUpload} >Upload</Button>
            </Modal.Footer>
        </Modal>
    )
}