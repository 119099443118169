import { METHODS } from "http";
import makeRequest from "./makeRequest";
import urls from "./urls";

export class UploadService {
    static async uploadFileToS3(formData:any) {
        return await makeRequest("/corpuser/uploadFileToS3", "POST", formData);
    }

    static async uploadCenters(formData:any) {
        return await makeRequest("/corpuser/upload-center", "POST", formData);
    }
}