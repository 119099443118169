import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import CenterTable from '../../component/Table/Center.table';
import ViewCentersTable from '../../component/Table/ViewCenters.table';
import { CorpsService } from '../../services/admin.service'
import { CSVLink } from 'react-csv';
import AddCenterModal from '../../component/Modals/AddCenter.modal';
import UploadCenters from '../../component/Modals/UploadCenters.modal';

const Dashboard = () => {
    const [corpsData, setCorpsData] = useState<any>();
    const [search, setSearch] = useState<any>("");
    const [centerData, setCenterData] = useState<any>();
    const [csvData, setCsvData] = useState<any>([]);
    const [centerCSVData, setCenterCSVData] = useState<any>([])

    const [addCenterShow, setAddCenterShow] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [centerActive, setCenterActive] = useState<boolean>(false);

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [showAddCenter, setShowAddCenter] = useState<boolean>(false);

    const getCorpsDetails = async () => {
        await CorpsService.getCorpDetails().then(res => {
            if (res.status === 200) {
                setCorpsData(res.data);
            }
        })
    }

    const downloadCSV = async () => {
        setActive(true)
        await CorpsService.downloadCSV().then((res) => {
            if (res.status === 200) {
                setCsvData(res.data);
            }
        })
        setActive(false)
    }


    const exportCenterCSV = async () => {
        setCenterActive(true);
        await CorpsService.exportCenterCSV().then((res) => {
            if (res.status === 200) {
                console.log(res.data, "center csv")
                setCenterCSVData(res.data);
            }
        })
        setCenterActive(false)
    }


    const getAllCorps = async () => {
        setLoading(true)
        await CorpsService.searchCorps(search, pageSize, pageNumber).then(res => {
            if (res.status === 200) {
                setCenterData(res.data);
            }
        }).catch(err => {
            setLoading(false)
        })
        setLoading(false)
    }

    const previousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const addCenter = () => {
        setAddCenterShow(true)
    }

    useEffect(() => {
        getCorpsDetails();
    }, [])


    useEffect(() => {
        getAllCorps();
    }, [search, pageNumber, pageSize, addCenterShow])

    return (
        <>
            <AddCenterModal
                show={addCenterShow}
                handleClose={() => {
                    setAddCenterShow(false);
                }}
            />

            <Container>
                <Row className='pt-5'>
                    <Col>
                        <Card className="shadow-sm p-1 mb-5 bg-white rounded">
                            <Card.Body>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div>
                                            <span className="fw-bold fs-15">Name:- </span>
                                            <span className="fw-bold text-muted">{corpsData && corpsData.name}</span>
                                        </div>
                                        <div>
                                            <span className="fw-bold fs-15">Email:- </span>
                                            <span className="fw-bold text-muted">{corpsData && corpsData.email}</span>
                                        </div>
                                    </div>
                                    <div>
                                        {centerCSVData && centerCSVData.length === 0
                                            ? <Button onClick={exportCenterCSV} disabled={centerActive}>Export Centers</Button> :
                                            <CSVLink
                                                data={centerCSVData}
                                                filename={"Centers.csv"}
                                                className="btn btn-warning"
                                                target="_blank"
                                            >
                                                Download All Centers
                                            </CSVLink>}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="shadow-sm p-1 mb-5 bg-white rounded">
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between">
                                    <span className="text-primary fw-bold fs-5">
                                        Registered Centers
                                    </span>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center me-2">

                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search by State, City and Corp"
                                                    onChange={(e: any) => {
                                                        setSearch(e.target.value);
                                                        getAllCorps();
                                                    }}
                                                    aria-describedby="basic-addon1"
                                                />
                                            </Form.Group>
                                            {loading === true && <Spinner className="d-flex align-items-center justify-content-center ms-2" animation="border" />}
                                        </div>
                                        {/* <div>
                                            {csvData && csvData.length === 0
                                                ? <Button onClick={downloadCSV} disabled={active}>Export Centers</Button> :
                                                <CSVLink
                                                    data={csvData}
                                                    filename={"Centers.csv"}
                                                    className="btn btn-warning"
                                                    target="_blank"
                                                >
                                                    Download Centers
                                                </CSVLink>}
                                        </div> */}
                                        <div>
                                            <Button onClick={addCenter} className='ms-2'>
                                                Add Center
                                            </Button>
                                            <Button onClick={() => setShowAddCenter(true)} className='ms-2'>
                                                Upload Center CSV
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label style={{ fontSize: 12, fontWeight: 800 }}>Page Size</Form.Label>
                                                <Form.Control type='number' value={pageSize} onChange={(e: any) => setPageSize(Number(e.target.value))} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>

                                        </Col>
                                        <Col md={3} className=' d-flex justify-content-end align-items-center'>
                                            <div>
                                                <div className='d-flex justify-content-end align-items-center'>
                                                    <Button size="sm" className='mt-3' onClick={previousPage}>
                                                        Previous
                                                    </Button>
                                                    <Button size="sm" className='mt-3 ms-2' onClick={nextPage}>
                                                        Next
                                                    </Button>
                                                </div>
                                                <div className='text-muted text-right mt-2' style={{ fontSize: 13 }}>
                                                    Page {pageNumber}
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>

                                </div>
                                <ViewCentersTable centers={centerData} reload={getAllCorps} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <UploadCenters show={showAddCenter} handleClose={() => setShowAddCenter(false)} />
        </>
    )
}

export default Dashboard